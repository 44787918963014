import BackGroundVideo from '~/layouts/components/BackGround/BackGroundVideo';
import { useStore } from '~/components/Hook';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

function Docs() {
    const src = `https://app.ibim.com.vn/view/training/arc`;
    let navigate = useNavigate();
    const store = useStore();
    const currentUser = store.state.user;
    //return <BackGroundVideo src={src} />;
    useEffect(() => {
        if (!currentUser) {
            return navigate('/');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUser]);
    return <>{currentUser && <div>{<BackGroundVideo src={src} />}</div>}</>;
}

export default Docs;
