import { Fragment } from 'react';

import config from '~/config';
import { HeaderOnly } from '~/layouts';
import Feedback from '~/pages/Feedback';
import Following from '~/pages/Following';
import Home from '~/pages/Home';
import Logout from '~/pages/Home/Logout';
import Profile from '~/pages/Profile';
import TimeSheet from '~/pages/TimeSheet';
import Coin from '~/pages/Coin';
import Docs from '~/pages/Docs';

//Public Routes

const publicRoutes = [
    {
        path: config.routes.home,
        component: Home,
    },
    {
        path: config.routes.following,
        component: Following,
    },
    {
        path: config.routes.profile,
        component: Profile,
    },
    {
        path: config.routes.timesheet,
        component: TimeSheet,
    },
    {
        path: config.routes.coin,
        component: Coin,
    },
    {
        path: config.routes.docs,
        component: Docs,
        layout: Fragment,
    },
    {
        path: config.routes.feedbacks,
        component: Feedback,
        layout: Fragment,
    },
    { path: config.routes.logout, component: Logout, layout: Fragment },
];

const privateRoutes = [
    {
        path: config.routes.home,
        component: Home,
    },
    {
        path: config.routes.following,
        component: Following,
    },
    {
        path: config.routes.profile,
        component: Profile,
        layout: HeaderOnly,
    },
    {
        path: config.routes.coin,
        component: Coin,
        layout: Fragment,
    },
    {
        path: config.routes.feedbacks,
        component: Feedback,
        layout: Fragment,
    },
];

export { privateRoutes, publicRoutes };
