import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCheckCircle,
    faSignOut,
    faEarthAsia,
    faCircleQuestion,
    faKeyboard,
    faBook,
} from '@fortawesome/free-solid-svg-icons';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import 'tippy.js/dist/tippy.css';
import { Link } from 'react-router-dom';

import config from '~/config';
import images from '~/assets/images';
import Button from '~/components/Button';
import Menu from '../Popper/Menu';
import Image from '~/components/Image';
//import Search from '../Search';
import Register from '../Popper/Register';
//import { CoinIcon } from '~/components/Icons';

import { useStore } from '~/components/Hook';
import classNames from 'classnames/bind';
import styles from './Header.module.scss';
import Search from '../Search';

const cx = classNames.bind(styles);
const MENU_ITEMS = [
    {
        icon: <FontAwesomeIcon icon={faEarthAsia} />,
        title: 'Languages',
        children: [
            {
                type: 'language',
                code: 'en',
                title: 'English',
            },
            {
                type: 'language',
                code: 'vi',
                title: 'Tiếng Việt',
                children: [
                    {
                        type: 'language',
                        code: 'vi-b',
                        title: 'Miền Bắc',
                    },
                    {
                        type: 'language',
                        code: 'vi-t',
                        title: 'Miền Trung',
                    },
                    {
                        type: 'language',
                        code: 'vi-n',
                        title: 'Miền Nam',
                    },
                ],
            },
        ],
    },
    {
        icon: <FontAwesomeIcon icon={faCircleQuestion} />,
        title: 'Feedback and help',
        to: '/feedback',
    },
    {
        icon: <FontAwesomeIcon icon={faKeyboard} />,
        title: 'Keyboard shortcuts',
    },
];
const MENU_USER = [
    {
        icon: <AccountBoxIcon sx={{ color: '#366cf4', fontSize: '20px' }}></AccountBoxIcon>,
        title: 'View profile',
        to: '/profile/me',
    },
    {
        icon: <FontAwesomeIcon icon={faBook} style={{ color: '366cf4', padding: '2px' }} />,
        title: 'Documents',
        to: '/docs',
    },
    {
        icon: <CalendarMonthIcon sx={{ color: '#366cf4', fontSize: '20px' }}></CalendarMonthIcon>,
        title: 'TimeSheet',
        to: '/timesheet',
    },
    {
        icon: <FontAwesomeIcon icon={faSignOut} />,
        title: 'Log out',
        to: '/logout',
        separate: true,
    },
];
function Header() {
    const store = useStore();
    const currentUser = store.state.user;
    return (
        <header className={cx('wrapper')}>
            <div className={cx('inner')}>
                <Link to={config.routes.home}>
                    <img className={cx('logo')} src={images.logo} alt="Logo" />
                </Link>
                {currentUser && <div className={cx('searchDiv')}>{<Search />}</div>}
                <div className={cx('actions')}>
                    {currentUser ? (
                        <>
                            {/* <Tippy delay={[0, 50]} content="Upload video" placement="bottom">
                                <button className={cx('action-btn')}>
                                    <UploadIcon></UploadIcon>
                                </button>
                            </Tippy>
                            <Tippy delay={[0, 50]} content="Message" placement="bottom">
                                <button className={cx('action-btn')}>
                                    <MessageIcon />
                                </button>
                            </Tippy>
                            <Tippy delay={[0, 50]} content="Inbox" placement="bottom">
                                <button className={cx('action-btn')}>
                                    <InboxIcon />
                                    <span className={cx('badge')}>12</span>
                                </button>
                            </Tippy> */}
                            <div className={cx('DivShareTitleContainer')}>
                                <h2 data-e2e="user-title" className={cx('H2ShareTitle')}>
                                    {currentUser.TenNhanVien}
                                </h2>
                                <h3 data-e2e="user-subtitle" className={cx('H1ShareSubTitle')}>
                                    {currentUser.ChucVu}
                                </h3>
                            </div>
                        </>
                    ) : (
                        <>
                            <Button
                                text
                                onClick={() => {
                                    window.open('//www.ibim.com.vn', '_blank');
                                }}
                            >
                                Home
                            </Button>
                            <Register></Register>
                            {/* <Button primary>Log in</Button> */}
                        </>
                    )}

                    <Menu key={currentUser} items={currentUser ? MENU_USER : MENU_ITEMS}>
                        {currentUser ? (
                            <div className={cx('div-avatar')}>
                                <div className={cx('div-info-tick')}>
                                    {currentUser.tick && (
                                        <FontAwesomeIcon
                                            className={cx('info-tick')}
                                            icon={faCheckCircle}
                                        ></FontAwesomeIcon>
                                    )}
                                </div>
                                <Image
                                    className={cx('user-avatar')}
                                    src={currentUser.Avatar}
                                    alt="avatar"
                                    customFallback={process.env.REACT_APP_NODE_SERVER_URL + 'img/avatar/default.png'}
                                ></Image>
                            </div>
                        ) : (
                            // <button className={cx('more-btn')}>
                            //     <FontAwesomeIcon icon={faEllipsisVertical} />
                            // </button>
                            <></>
                        )}
                    </Menu>
                </div>
            </div>
        </header>
    );
}

export default Header;
