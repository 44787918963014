import axios from 'axios';

const nodeRequest = axios.create({
    baseURL: process.env.REACT_APP_NODE_SERVER_URL,
    timeout: 15000,
    withCredentials: true,
    headers: { 'X-Custom-Header': 'foobar' },
});

export const get = async (path, options = {}) => {
    const response = await nodeRequest.get(path, options);
    return response.data;
};
// export const post = async (path, options = {}) => {
//     const response = await nodeRequest.get(path, options);
//     return response.data;
// };
export const post = async (path, data = {}, config = {}) => {
    const response = await nodeRequest.post(path, data, config);
    return response.data;
    // if (config) {
    //     const response = await nodeRequest.post(path, data, config);
    //     return response.data;
    // } else {
    //     const response = await nodeRequest.post(path, data);
    //     return response.data;
    // }
};
export default nodeRequest;
