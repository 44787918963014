import classNames from 'classnames/bind';
import styles from './BackGroundVideo.module.scss';
// import CurtainBackGround from './CurtainBackGround';
const cx = classNames.bind(styles);

function BackGroundVideo({ src }) {
    //const [show, setShow] = useState('none');
    // useEffect(() => {
    //     setTimeout(() => {
    //         setShow('block');
    //     }, 5000);
    // }, []); style={{ display: show }}
    //const src = `https://www.youtube.com/embed/${id}?controls=0&showinfo=0&rel=0&autoplay=1&mute=1&loop=1&playlist=${id}`;
    return (
        <div className={cx('video-background')}>
            <div className={cx('video-foreground')}>
                <iframe
                    title={src}
                    src={src}
                    //allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    id="videoBackground"
                ></iframe>
                {/* <iframe
                    id="recruiting-video-cover"
                    className="transition-opacity fluidvids-item"
                    src="https://player.vimeo.com/video/777147453?h=d4f76f0bd7&autopause=1&autoplay=1&background=1&loop=1&muted=1&playsinline=1"
                    width="640"
                    height="360"
                    frameBorder="0"
                    allowFullScreen
                    allow="autoplay; fullscreen"
                    title="Join us in shaping the future of technology"
                    data-fluidvids="loaded"
                    data-ready="true"
                ></iframe> */}
            </div>
            {/* <CurtainBackGround></CurtainBackGround> */}
        </div>
    );
}

export default BackGroundVideo;
