import nodeRequest from '~/ulti/nodeRequest';
import { getJob } from '~/services/jobService';

export const auth = async (data) => {
    try {
        const res = await nodeRequest.post('login/password', {
            username: data.username,
            password: data.password,
            _csrf: data._csrf,
        });
        if (typeof res.data === 'string') {
            data.setError(res.data);
        } else {
            return getAcc(res.data);
        }
    } catch (error) {
        if (error.message === 'timeout of 5000ms exceeded') data.setError('Server không phản hồi, hãy thử lại !!');
        console.log(error);
    }
};
export const getToken = async () => {
    try {
        const res = await nodeRequest.get('login');
        return res.data;
    } catch (error) {
        console.log(error);
    }
};
export const tryGetAcc = async () => {
    try {
        const res = await nodeRequest.get('login/acc');
        if (res.data) {
            return getAcc(res.data);
        }
    } catch (error) {
        console.log(error);
    }
};
export const getAcc = async (acc) => {
    try {
        const user = await getUser();
        const jobs = await getJob(acc);
        const payload = {
            account: acc,
            user,
            jobs,
        };
        return payload;
    } catch (error) {
        console.log(error);
    }
};
export const getUser = async () => {
    try {
        const res = await nodeRequest.get('login/user');
        return res.data;
    } catch (error) {
        console.log(error);
    }
};
export const logOut = async () => {
    try {
        const res = await nodeRequest.get('logout');
        return res;
    } catch (error) {
        console.log(error);
    }
};
