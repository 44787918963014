import React from 'react';
import { Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

import classNames from 'classnames/bind';
import styles from './Slideshow.module.scss';
const cx = classNames.bind(styles);

const divStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    height: 'calc(100vh - 100px)',
};
const slideImages = [
    {
        url: process.env.REACT_APP_NODE_SERVER_URL + 'img/banner/khoa1/to0.jpg',
        caption: 'Slide 1',
    },
    {
        url: process.env.REACT_APP_NODE_SERVER_URL + 'img/banner/khoa1/to1.jpg',
        caption: 'Slide 2',
    },
    {
        url: process.env.REACT_APP_NODE_SERVER_URL + 'img/banner/khoa1/to2.jpg',
        caption: 'Slide 3',
    },
];
const Slideshow = () => {
    return (
        <div className={cx('slide-container')}>
            <Fade>
                {slideImages.map((slideImage, index) => (
                    <div key={index}>
                        <div style={{ ...divStyle, backgroundImage: `url(${slideImage.url})` }}></div>
                    </div>
                ))}
            </Fade>
        </div>
    );
};

export default Slideshow;
