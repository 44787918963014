const routes = {
    home: '/',
    following: '/following',
    profile: '/profile/:username',
    timesheet: '/timesheet/',
    upload: '/upload',
    feedback: '/feedback',
    coin: '/mycoins',
    docs: '/docs',
    logout: '/logout',
};
export default routes;
